<template>
  <v-dialog persistent 
    v-model="activeModal"
    :max-width="'700px'"
    
    class="modal-cadastro">
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
        <v-spacer></v-spacer>
        <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <v-card-title class="modal-cadastro-titulo">
       {{ caixa.name }}
      </v-card-title>
      
      <v-card-text>
        <div class="cadastro-usuario modal-lista-operacao-financeira">
          <div class="box-table">
            <v-skeleton-loader
              :loading="loadingTable"
              :transition="'fade-transition'"
              height="500"
              type="table">
              <v-data-table
                :headers="headers"
                :search="search"
                :items="listaItens"
                width="300px"
                max-width="500px"
                flat
                hide-default-footer
                :loading="loadingPag"
                :items-per-page.sync="itemsPerPage"
                dense>
                  <template v-slot:item.action="{ item }">
                    <v-btn
                      small
                      @click="activeModelFinanceiro(item)"
                      tile
                      outlined 
                      :color="variables.colorSecondary"
                    >
                      Financeiro
                    </v-btn>
                  </template>



                  <template v-slot:footer>
                    <div class="wrapper-footer">
                      <div class="qtd-paginacao">
                        {{ pageStart }}
                        -
                        {{ pageStop }}
                        de
                        {{ totalItens }}
                      </div>
                      <v-pagination
                        v-model="page"
                        :length="numberOfPages"
                        circle
                        :disabled="disabled"
                        :total-visible="7"
                        class="box-table-paginacao"
                        v-if="numberOfPages > 1"></v-pagination>
                    </div>
                  </template>
              </v-data-table>
            </v-skeleton-loader>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
// eslint-disable-next-line
import { size } from 'lodash'
// eslint-disable-next-line
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
import listagemTables from '@/views/mixins/listagemTables'

export default {
  name: 'ModalSmsMensagem',
  mixins: [listagemTables],
  data: () => ({
    activeModal: false,
    headers: [
      { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'Tipo de operação', value: 'pendingOperationTypeName' },
      { align: 'start', class: 'table-header', text: 'Nome', value: 'name' },
      { align: 'start', class: 'table-header', text: 'Valor', value: 'value' },
      { align: 'start', class: 'table-header', text: 'data do lançamento', value: 'date' },
      // { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
    ],
    caixa: {
      name: ''
    }
  }),
  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('lancamentoFinanceiro', ['listaItens', 'totalItens']),
    variables: () => variables,
    itemsPerPage () {
      return this.$store.getters.ItensPaginacao
    },

    pageStop () {
      const result = this.$store.getters.ItensPaginacao * this.page
      return result < this.totalItens ? result : this.totalItens
    },

    pageStart () {
      return this.pageStop - this.listaItens.length + 1
    }
  },
  mounted () {
    Events.$on('modalListCaixa::operacoesFinanceiras', item => {
      this.activeModal = true
      this.loadingTable = true
      this.caixa = item
      this.getItens({_filter: {"entityId_In": `${item.id}`}, module: 'Revendas' }).finally(() => this.loadingTable = false)
    })
  },
  methods: {
    ...mapActions('lancamentoFinanceiro', ['getItens']),
    closeModal () {
      this.activeModal = false
    },

    // sobrescrevendo o mixin de listagem de tabela para nao fazer requisição atoa
    init () {}
  },

  beforeDestroy () {
    Events.$off('modalListCaixa::operacoesFinanceiras')
  }
}
</script>

<style lang="scss">
@import '~@/assets/styles/components/modal-cadastro';
.modal-lista-operacao-financeira .v-data-table__wrapper table {
  min-width: 600px;
}
</style>
